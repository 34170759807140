import jwtDecode from 'jwt-decode';
import { Cookies } from "react-cookie";

const crypto = require('crypto');
const algorithm = 'aes-256-cbc';
const key = process.env.REACT_APP_KEY;

export const encrypt = (text) =>{
    let cipher = crypto.createCipher(algorithm,key)
    let crypted = cipher.update(text,'utf8','hex')
    crypted += cipher.final('hex');
  
    return crypted;
}
  
export const decrypt = (text) =>{
    let decipher = crypto.createDecipher(algorithm,key)
    let dec = decipher.update(text,'hex','utf8')
    dec += decipher.final('utf8');
    return dec;
};
  
/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    const subtoken_cobcloud = decrypt(decoded.sub);    

    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    }
    /*
    else if (subtoken_cobcloud === "cobcloud") {
        console.warn('token not cobcloud');
        return false;
    }*/
    else {
        return true;
    }
}



/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies(); 
    let user = cookies.get("cob");
    
    if (user){
        try{
            user = decrypt(user);
            return user ? (typeof(user) == 'object'? user: JSON.parse(user)) : null;
        }catch{
            return null;
        }
    }    
}

/**
 * Return idempresa criptografado
 */
const getLoggedIdEmpresa = () => {
    const cookies = new Cookies(); 
    let user = cookies.get("cobx");
    
    if (user){
        try{
            user = decrypt(user);
            return user;
        }catch{
            return null;
        }
    }    
}

export { isUserAuthenticated, getLoggedInUser, getLoggedIdEmpresa };