import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import divSaga from './diversos/saga';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        divSaga(),
    ]);
}
