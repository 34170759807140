//import { all, call, fork, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { all, fork, takeLatest } from 'redux-saga/effects';

import {
    SELECIONA_CREDOR
} from '../../constants/actionTypes';

/*
import {
    comboIdCredor
} from './actions';
*/

export function* idCredor() {
    //console.log('Hello Sagas!')
    //yield put(comboIdCredor("555"));    
}

export function* watchHelloSaga():any {
    //yield takeEvery(SELECIONA_CREDOR, hello);
    yield takeLatest(SELECIONA_CREDOR, idCredor);
}

function* divSaga():any {
    yield all([
        fork(watchHelloSaga),
    ]);
}

export default divSaga;