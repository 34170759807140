import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'

ReactDOM.render(<Provider store={configureStore()}>
    <ReactNotification />
    <App />
</Provider>, document.getElementById('root'));

serviceWorker.unregister();
