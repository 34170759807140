import React from 'react';
import { Redirect, Route } from "react-router-dom";

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';

// lazy load all the views
const Dashboard = React.lazy(() => import('./pages/dashboards/'));

const ListaDevedores = React.lazy(() => import('./pages/relatorios/devedores/ListaDevedores'));
const ListaOcorrencias = React.lazy(() => import('./pages/relatorios/ocorrencias/ListaOcorrencias'));
const ListaTitulos = React.lazy(() => import('./pages/relatorios/titulos/ListaTitulos'));
const ListaPrestacoes = React.lazy(() => import('./pages/relatorios/prestacoes/ListaPrestacoes'));
const pdfPrestacao = React.lazy(() => import('./pages/relatorios/prestacoes/pdfVisualizaPrestacao'));
const ListaDevolucoes = React.lazy(() => import('./pages/relatorios/devolucoes/ListaDevolucoes'));
const pdfDevolucoes = React.lazy(() => import('./pages/relatorios/devolucoes/pdfVisualizaDevolucao'));
const pdfExtratoProcessos = React.lazy(() => import('./pages/relatorios/prestacoes/pdfExtratoProcessos/pdfExtratoProcessos'));
const infoContato = React.lazy(() => import('./pages/diversos/infocontato'));
const Devedor = React.lazy(() => import('./pages/relatorios/devedores/CadastroDevedor'));
const novoDevedor = React.lazy(() => import('./pages/relatorios/devedores/NovoDevedor'));
const Processo = React.lazy(() => import('./pages/relatorios/processo/DetalheProcesso'));
const Senha = React.lazy(() => import('./pages/diversos/AlterarSenha'));
const Bordero = React.lazy(() => import('./pages/envioArquivos/envioArquivos'));
const RelatoriosPersonalizados = React.lazy(() => import('./pages/relatorios/personalizados/RelatoriosPersonalizados'));
const ConsultaDocumento = React.lazy(() => import('./pages/consultaDocumento/ConsultaDocumento'))

// auth
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const Register = React.lazy(() => import('./pages/account/Register'));
const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));
//const Erro404 = React.lazy(() => import('./pages/diversos/Erro404'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
    const isAuthTokenValid = isUserAuthenticated();
    if (!isAuthTokenValid) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }

    const loggedInUser = getLoggedInUser();
    // check if route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: '/' }} />
    }

    // authorised so return component
    return <Component {...props} />
  }} />
)

const routes = [
  // auth and account
  { path: '/login', name: 'Login', component: Login, route: Route },
  { path: '/logout', name: 'Logout', component: Logout, route: Route },
  { path: '/forget-password', name: 'Forget Password', component: ForgetPassword, route: Route },
  { path: '/register', name: 'Register', component: Register, route: Route },
  { path: '/confirm', name: 'Confirm', component: ConfirmAccount, route: Route },
  
  // other pages
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute, roles: ['Admin'] },
  { path: '/lista_devedores', name: 'Lista de Devedores', component: ListaDevedores, route: PrivateRoute, roles: ['Admin'] },
  { path: '/lista_ocorrencias', name: 'Histórico de Ocorrências', component: ListaOcorrencias, route: PrivateRoute, roles: ['Admin'] },
  { path: '/lista_titulos', name: 'Lista de Títulos', component: ListaTitulos, route: PrivateRoute, roles: ['Admin'] },
  { path: '/lista_prestacoes', name: 'Lista de Prestações', component: ListaPrestacoes, route: PrivateRoute, roles: ['Admin'] },
  { path: '/pdf_prestacao/:id', name: 'PDF Prestação', component: pdfPrestacao, route: PrivateRoute, roles: ['Admin'] },  
  { path: '/pdf_extrato_processos/:id', name: 'PDF Extrato Processo', component: pdfExtratoProcessos, route: PrivateRoute, roles: ['Admin'] },
  { path: '/lista_devolucoes', name: 'Lista de Devoluções', component: ListaDevolucoes, route: PrivateRoute, roles: ['Admin'] },
  { path: '/pdf_devolucoes/:id', name: 'PDF Devoluções', component: pdfDevolucoes, route: PrivateRoute, roles: ['Admin'] },  
  { path: '/contato', name: 'Contato', component: infoContato, route: PrivateRoute, roles: ['Admin'] },  
  { path: '/devedor/:id', name: 'Devedor', component: Devedor, route: PrivateRoute, roles: ['Admin'] },  
  { path: '/devedor_novo', name: 'Devedor', component: novoDevedor, route: PrivateRoute, roles: ['Admin'] },  
  { path: '/processo/:id', name: 'Processo', component: Processo, route: PrivateRoute, roles: ['Admin'] },
  { path: '/borderos_cobranca', name: 'Bordero', component: Bordero, route: PrivateRoute, roles: ['Admin']},
  { path: '/relatorios_personalizados', name: 'Relatórios Personalizados', component: RelatoriosPersonalizados, route: PrivateRoute, roles: ['Admin']},
  { path: '/consulta_documento', name: 'Consulta Documento', component: ConsultaDocumento, route: PrivateRoute, roles: ['Admin']},
  { path: '/alterar_senha', name: 'Alterar Senha', component: Senha, route: PrivateRoute, roles: ['Admin'] },        
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute
  },   
  //{ path: '*', name: 'Page 404', component: Erro404, route: Route },
  
]

export { routes, PrivateRoute };