import {
    SELECIONA_CREDOR,
    LIST_CREDORES_GRUPO
} from '../../constants/actionTypes';

const INIT_STATE = {
    seleciona_credor: 0,
    listCredoresGrupo: [],
};

const diversosReducer = (state = INIT_STATE, action) => {    
    switch (action.type) {        
        case SELECIONA_CREDOR:{            
            return { ...state, seleciona_credor: action.seleciona_credor };
        }
        case LIST_CREDORES_GRUPO:{            
            return { ...state, listCredoresGrupo: action.listCredoresGrupo };
        }
        default: return { ...state };
    }
}

export default diversosReducer;