import api2 from "../services/api2";

export function configureFakeBackend() {
  let users = [];
  let realFetch = window.fetch;

  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      //setTimeout(() => {

      // authenticate
      if (url.endsWith("/users/authenticate") && opts.method === "POST") {
        // get parameters from post request
        let params = JSON.parse(opts.body);

        let conta = params.conta;
        conta = conta.toUpperCase();
        let username = params.username;
        username = username.toUpperCase();
        let password = params.password;
        password = password.toUpperCase();

        const meuip = params.meuip || "";

        api2
          .post("/login_credor", { conta, username, password, meuip })
          .then(function (response) {
            let responseJson = {
              idempresa: response.data.idempresa,
              hash_empresa: response.data.hash_empresa,
              empresa: response.data.empresa,
              empresafone: response.data.empresafone,
              empresafone2: response.data.empresafone2,
              username: response.data.ac_login,
              id: response.data.id,
              razaosocial: response.data.razaosocial,
              cnpj: response.data.cnpj,
              perfil: response.data.perfil,
              relPersonalizados: response.data.relatorios_personalizados,
              acesso_pc: response.data.sem_acesso_pcs,
              acesso_acordo: response.data.acesso_acordos,
              uuid: response.data.id_uuid,
              //firstName: response.data.razaosocial,
              //lastName: response.data.razaosocial,
              role: "Admin",
              token: response.data.token,
            };

            //console.log(responseJson);

            return resolve({ ok: true, json: () => responseJson });
          })
          .catch(function () {
            return reject("Usuário e/ou senha incorretos.");
          });
        return;
      }

      // register
      /*
                if (url.endsWith('/users/register') && opts.method === 'POST') {
                    // get parameters from post request
                    let params = JSON.parse(opts.body);

                    // add new users
                    let { firstName, lastName } = params.fullname.split(" ");
                    let newUser = { id: users.length + 1, username: firstName, password: params.password, firstName: firstName, lastName: lastName, role: 'Admin' }
                    users.push({ newUser })

                    let responseJson = {
                        id: newUser.id,
                        username: newUser.username,
                        firstName: newUser.firstName,
                        lastName: newUser.lastName,
                        role: newUser.role,
                        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDb2RlcnRoZW1lIiwiaWF0IjoxNTU1NjgyNTc1LCJleHAiOjE1ODcyMTg1NzUsImF1ZCI6ImNvZGVydGhlbWVzLmNvbSIsInN1YiI6InRlc3QiLCJmaXJzdG5hbWUiOiJIeXBlciIsImxhc3RuYW1lIjoiVGVzdCIsIkVtYWlsIjoidGVzdEBoeXBlci5jb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4ifQ.8qHJDbs5nw4FBTr3F8Xc1NJYOMSJmGnRma7pji0YwB4'
                    };
                    resolve({ ok: true, json: () => responseJson });
                    return;
                }
                */

      // forget password
      /*
                if (url.endsWith('/users/password-reset') && opts.method === 'POST') {
                    // get parameters from post request
                    let params = JSON.parse(opts.body);

                    // find if any user matches login credentials
                    let filteredUsers = users.filter(user => {
                        return user.username === params.username;
                    });

                    if (filteredUsers.length) {
                        let responseJson = {
                            message: "We've sent you a link to reset password to your registered email."
                        };
                        resolve({ ok: true, json: () => responseJson });
                    } else {
                        // else return error
                        reject('Sorry, we could not find any registered user with entered username');
                    }
                    return;
                }
                */

      // get users
      if (url.endsWith("/users") && opts.method === "GET") {
        // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
        if (
          opts.headers &&
          opts.headers.Authorization ===
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJDb2RlcnRoZW1lIiwiaWF0IjoxNTU1NjgyNTc1LCJleHAiOjE1ODcyMTg1NzUsImF1ZCI6ImNvZGVydGhlbWVzLmNvbSIsInN1YiI6InRlc3QiLCJmaXJzdG5hbWUiOiJIeXBlciIsImxhc3RuYW1lIjoiVGVzdCIsIkVtYWlsIjoidGVzdEBoeXBlci5jb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4ifQ.8qHJDbs5nw4FBTr3F8Xc1NJYOMSJmGnRma7pji0YwB4"
        ) {
          resolve({ ok: true, json: () => users });
        } else {
          // return 401 not authorised if token is null or invalid
          reject("Unauthorised");
        }

        return;
      }

      // pass through any requests not handled above
      realFetch(url, opts).then((response) => resolve(response));

      //}, 500);
    });
  };
}
